import * as ZXing from '../node_modules/@zxing/library/esm/index.js';
import { Base64 } from './base64';

const onLoad = function () {
  const resultHashConstants = {
    result: '#r+/=',
    qr_paste: '#qr_paste',
    b64: '#base64=',
    text: '#text='
  };

  const setErrorText = (err) => {
    console.error(err);
    alert('Error: ' + err);
  };

  const contentTextField = document.getElementById('content');
  const result = document.getElementById('result');
  const qr = document.getElementById('qr');
  const video = document.getElementById('video');
  const preview = document.getElementById('preview');
  const fileUpload = document.getElementById('file');
  const download = document.getElementById('download');
  const autoNavigate = document.getElementById('auto-navigate');
  const message = document.getElementById('message');

  const BASE64_ENCODING = ';base64'
  const CONTENT_TYPES = {
    TEXT: /^text\/plain/,
    TEXT_CONSTANT: 'text/plain',
    IMAGE: /^image\//
  };
  const EMPTY_CONTENT = {
    type: 'text/plan',
    value: ''
  };
  let content = EMPTY_CONTENT;

  const serializeContent = function(content) {
    const isText = content.type.match(CONTENT_TYPES.TEXT);
    return 'data:' + content.type + BASE64_ENCODING + ',' + (isText ? Base64.encode(content.value) : content.value);
  };
  const deserializeContent = function(content) {
    if (!content.startsWith('data:')) {
      console.error('not data url: ', content.substring(0, 10000));
      return null;
    }
    const serializedType = content.match(/^data:[^.]*,/)[0];
    if (!serializedType) { return null; }
    const isBase64Encoded = serializedType.endsWith(BASE64_ENCODING + ',');
    const type = serializedType.substring('data:'.length, serializedType.length - (isBase64Encoded ? BASE64_ENCODING.length : 0) - ','.length);
    const isEncodedText = isBase64Encoded && type.match(CONTENT_TYPES.TEXT);
    const encodedValue = content.substring(serializedType.length);
    if (!isEncodedText && !isBase64Encoded) {
      console.error('not encoded text and not base64: ', content.substring(0, 10000));
      return null;
    }
    return { 
      type: isEncodedText ? CONTENT_TYPES.TEXT_CONSTANT : type,
      value: isEncodedText ? Base64.decode(encodedValue) : encodedValue,
    };
  };

  const contentChanged = () => {
    const codeWriter = new ZXing.BrowserQRCodeSvgWriter();
    while (qr.firstChild) { qr.firstChild.remove(); }
    window.location.hash = resultHashConstants.result + serializeContent(content.type.match(CONTENT_TYPES.TEXT) || true ? content : EMPTY_CONTENT);
    console.log('New value: ' + JSON.stringify(content));
    if (content.value) {
      try {
        codeWriter.writeToDom(qr, window.location.toString(), 300, 300);
      } catch (e) { }
    }
    const isTextContent = content.type.match(CONTENT_TYPES.TEXT); 
    const textContent = isTextContent ? content.value : '';
    const isImageContent = content.type.match(CONTENT_TYPES.IMAGE);
    contentTextField.value = textContent;
    if (isImageContent) {
      preview.src = 'data:' + content.type + BASE64_ENCODING + ',' + content.value;
      preview.style.display = 'block';
    } else {
      preview.style.display = 'none';
    }
    if (!isTextContent) {
      download.style.display = 'block';
      download.href = 'data:' + content.type + BASE64_ENCODING + ',' + content.value;
    } else {
      download.style.display = 'none';
    }
    if (content.type.match(CONTENT_TYPES.TEXT) && content.value.match(/^http(s)?:\/\//)) {
      autoNavigate.href = content.value;
      autoNavigate.innerText = content.value.length > 100 ? content.value.substring(0, 100) + "..." : content.value;
      autoNavigate.style.display = 'block';
    } else {
      autoNavigate.style.display = 'none';
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(contentTextField.value);
    message.innerText = 'Copied To Clipboard';
    setTimeout(() => { message.innerText = ''; }, 1000);
  };

  document.getElementById('pasteContent').addEventListener('click', () => {
    navigator.clipboard.readText().then(text => {
      contentTextField.value = text;
      contentChanged();
    }).catch(setErrorText);
  });
  document.getElementById('copy').addEventListener('click', () => {
    copyToClipboard();
  });
  contentTextField.addEventListener('keyup', () => {
    content = {
      type: CONTENT_TYPES.TEXT_CONSTANT,
      value: contentTextField.value
    };
    contentChanged();
  });
  fileUpload.addEventListener('change', () => {
    var reader = new FileReader();
    reader.onload = function(event) {
      try {
        content = deserializeContent(event.target.result);
        if (!content) {
          content = EMPTY_CONTENT;
        }
        contentChanged();
      } catch (e) { setErrorText(e); }
    };
    reader.readAsDataURL(fileUpload.files[0]);
  });

  document.onpaste = function(ev) {
    if (document.activeElement && document.activeElement.tagName.toLowerCase() == 'input') {
      return;
    }
    ev.preventDefault();
    const data = (event.clipboardData || event.originalEvent.clipboardData).items;
    let parsedOne = false;
    const unparsedTypes = [];
    for (var i = 0; i < data.length; i += 1) {
      if ((data[i].kind == 'string') &&
          (data[i].type.match('^text/plain'))) {
        // This item is the target node
        data[i].getAsString(function (text) {
          try {
            content = {
              type: CONTENT_TYPES.TEXT_CONSTANT,
              value: text
            };
            contentChanged();
          } catch (e) { setErrorText(e); }
        });
        parsedOne = true;
      } else if (data[i].kind == 'file') {
        const file = data[i].getAsFile();
        
        var reader = new FileReader();
        reader.onload = function(event) {
          try {
            content = deserializeContent(event.target.result);
            if (!content) {
              content = EMPTY_CONTENT;
            }
            contentChanged();
          } catch (e) { setErrorText(e); }
        };
        reader.readAsDataURL(file);
        parsedOne = true;
      } else {
        unparsedTypes.push({ kind: data[i].kind, type: data[i].type });
      }
    }
    if (!parsedOne && unparsedTypes.length > 0) {
      alert('Ups, can\'t parse clipboard content: kind=' + data[0].kind + ' type=' + data[0].type);
    }
  }
  
  const parseFromHash = (hash) => {
    if (hash.startsWith(resultHashConstants.result)) {
      return { 'result': deserializeContent(hash.substring(resultHashConstants.result.length)) };
    } else if (hash.startsWith(resultHashConstants.qr_paste)) {
      return {'qr_paste' : true};
    } else if (hash.startsWith(resultHashConstants.b64)) {
      return {'result' :  { 
        type: CONTENT_TYPES.TEXT_CONSTANT,
        value: Base64.decode(hash.substring(resultHashConstants.b64.length))}
      };
    } else if (hash.startsWith(resultHashConstants.text)) {
      return {'result' :  { 
        type: CONTENT_TYPES.TEXT_CONSTANT,
        value: decodeURIComponent(hash.substring(resultHashConstants.text.length))}
      };
    } else if (hash) {
      console.error('Invalid hash', hash);
    }
    return null;
  };

  const codeReader = new ZXing.BrowserQRCodeReader();
  var receive = () => {
    codeReader.getVideoInputDevices()
      .then((videoInputDevices) => {
        const sourceSelect = document.getElementById('sourceSelect')
        const selectedDeviceId = videoInputDevices[0].deviceId
        if (videoInputDevices.length > 1) {
          videoInputDevices.forEach((element) => {
            const sourceOption = document.createElement('option')
            sourceOption.text = element.label;
            sourceOption.value = element.deviceId;
            sourceSelect.appendChild(sourceOption)
          })

          sourceSelect.onchange = () => {
            selectedDeviceId = sourceSelect.value;
          }

          const sourceSelectPanel = document.getElementById('sourceSelectPanel')
          sourceSelectPanel.style.display = 'block'
        }

        const showVideo = (show) => {
          video.style.display = show ? 'block' : 'none';
        };
        showVideo(true);
        codeReader.decodeFromVideoDevice(selectedDeviceId, 'video', (qrResult, err) => {
          if (qrResult) {
            codeReader.reset();
            showVideo(false);
            console.log(qrResult);
            const encodedResult = qrResult.text;
            const indexOfHash = encodedResult.indexOf('#');
            if (indexOfHash < 0) {
              return;
            }
            content = parseFromHash(encodedResult.substring(indexOfHash));
            console.log("Pasting from camera: " + JSON.stringify(content));
            contentChanged();
            copyToClipboard();
          }
          if (err && !(err instanceof ZXing.NotFoundException)) {
            console.log(err);
          }
        });
        console.log(`Started continous decode from camera with id ${selectedDeviceId}`);
      })
      .catch(setErrorText);
  };
  document.getElementById('receive').addEventListener('click', receive);

  const initialResult = parseFromHash(window.location.hash);
  if (initialResult) {
    if (initialResult.result) {
      content = initialResult.result;
      contentChanged(); 
      copyToClipboard();
    } else if (initialResult.qr_paste) {
      receive();
    }
  }
};
window.addEventListener('load', function() {
  try {
    onLoad();
  } catch (e) {
    alert('Something went wrong during initialization, sorry :(');
    console.error(e);
  }
});
